
import React, { useState, useEffect } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link } from 'react-router-dom'
import 'primereact/resources/themes/lara-light-blue/theme.css';
//primereact/resources/themes/fluent-light/theme.css
//primereact/resources/themes/lara-light-blue/theme.css
//primereact/resources/themes/lara-light-indigo/theme.css
import { makeRequest2 } from '../../../utils/makeRequest'
import { toastify } from '../../../utils/toast'
import { getWaterMetersURL } from '../../../utils/urls'
import Layout from '../component/layout'
import { filterObjectsByAllProperties } from '../../../utils/filterSearch';
function Meters() {
  const [meters, setMeters] = useState([])
  const [filtermeters, setFilteredMeters] = useState([])
  const [searchTerm, setSearchTerm] = useState('')

  const getMeters = async () => {
    try {
      const response = await makeRequest2(getWaterMetersURL, 'GET', {})
      console.log(response)
      if (response.success) {
        setMeters(response.data)
        setFilteredMeters(response.data)

      }
    }
    catch (error) {
      toastify(error.message, 'error')
    }
  }
  const HandeInputSearch = async (value) => {
    try {
      setSearchTerm(value)
      if (value === '') {
        getMeters()
      }
      else {
        const results = await filterObjectsByAllProperties(meters, value);
        setMeters(results)
      }
    }
    catch (error) {
      toastify(error.message, 'error')
    }
  }

  const HandeFilter = async (value, filter) => {
    try {
      if (filter === 'All') {
        getMeters()
      }
      else if (filter === 'Valve Status') {

        const results = await filtermeters.filter((x) => { return x.valveStatus === value })
        setMeters(results)
      }
      else if (filter === 'In Stock') {

        const results = await filtermeters.filter((x) => { return x.inStock === value })
        setMeters(results)
      }

      else if (filter === 'Is Faulty') {

        const results = await filtermeters.filter((x) => { return x.isFaulty === value })
        setMeters(results)
      }
      else if (filter === 'Is Installed') {

        const results = await filtermeters.filter((x) => { return x.isInstalled === value })
        setMeters(results)
      }
      else if (filter === 'Not In Stock') {

        const results = await filtermeters.filter((x) => { return x.inStock === value })
        setMeters(results)
      }
      else if (filter === 'Not Faulty') {

        const results = await filtermeters.filter((x) => { return x.isFaulty === value })
        setMeters(results)
      }
      else if (filter === 'Not Installed') {

        const results = await filtermeters.filter((x) => { return x.isInstalled === value })
        setMeters(results)
      }
    }
    catch (error) {
      toastify(error.message, 'error')
    }
  }

  const statusBodyTemplate = (rowData) => {

    if (rowData.valveStatus === 'Open') {
      return (

        <span className="badge rounded-pill text-bg-success">Open</span>
      )
    }
    else {
      return (

        <span className="badge rounded-pill text-bg-danger">Closed</span>
      )
    }

  };

  useEffect(() => {
    getMeters()
  }, [])
  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/core/dashboard"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/core/water_management/water_meters"}>Water Meters </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">


          <div className="row">

            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-4">

                      <div className="row">
                        <div className="col-9">
                          <input className="form-control" placeholder="Search here" value={searchTerm} onChange={(e) => { HandeInputSearch(e.target.value) }} />

                        </div>
                        <div className="col-3" >
                          <div className="btn-group-dropdown" style={{ float: 'right' }}>
                            <button
                              className="btn btn-outline-default dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i className="ti ti-filter"></i>
                            </button>
                            <div className="dropdown-menu">
                              <Link className="dropdown-item" to="#!" onClick={() => { HandeFilter('', 'All') }}>
                                All
                              </Link>
                              <Link className="dropdown-item" to="#!" onClick={() => { HandeFilter('Open', 'Valve Status') }}>
                                Open
                              </Link>
                              <Link className="dropdown-item" to="#!" onClick={() => { HandeFilter('Closed', 'Valve Status') }}>
                                Closed
                              </Link>
                              <Link className="dropdown-item" to="#!" onClick={() => { HandeFilter(true, 'In Stock') }}>
                                In Stock
                              </Link>
                              <Link className="dropdown-item" to="#!" onClick={() => { HandeFilter(true, 'In Faulty') }}>
                                Is Faulty
                              </Link>
                              <Link className="dropdown-item" to="#!" onClick={() => { HandeFilter(true, 'Is Installed') }}>
                                Is Installed
                              </Link>
                              <Link className="dropdown-item" to="#!" onClick={() => { HandeFilter(false, 'Not In Stock') }}>
                                Not In Stock
                              </Link>
                              <Link className="dropdown-item" to="#!" onClick={() => { HandeFilter(false, 'Not Faulty') }}>
                                Not Faulty
                              </Link>
                              <Link className="dropdown-item" to="#!" onClick={() => { HandeFilter(false, 'Not Installed') }}>
                                Not Installed
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div className="col-8">
                      <Link to={"/core/water_management/add_water_meter"} type="button" className="btn btn-primary float-end" style={{ color: 'white' }}>
                        <i className="ti ti-plus" />&nbsp;Add</Link>

                      <Link to={"/core/water_management/import_water_meters"} type="button" className="btn btn-primary float-end" style={{ marginRight: 4 }}>
                        <i className="ti ti-file-import" />
                        &nbsp;
                        Import
                      </Link>
                    </div>
                  </div>

                </div>
                <div className="card-body">
                  <div className="dt-responsive table-responsive">
                    <DataTable value={meters} emptyMessage="No water meters found." sortMode="multiple" paginator rows={5} stripedRows tableStyle={{ minWidth: '50rem' }}>

                      <Column field="serialNumber" sortable header="Serial Number"></Column>
                      <Column field="manufacturer" header="Manufacturer"></Column>
                      <Column field="size" header="Size"></Column>
                      <Column field="initialValue" header="Initial Value"></Column>
                      <Column field="currentValue" header="Current Value" ></Column>
                      <Column field="status" sortable header="Status" body={statusBodyTemplate}></Column>
                      <Column field="location" sortable header="Location" ></Column>
                    </DataTable>


                  </div>
                </div>
              </div>
            </div>


          </div>

        </div>
      </div>
    </Layout>
  )
}


export default Meters