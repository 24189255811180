import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeRequest2 } from '../../../utils/makeRequest';
import { toastify } from '../../../utils/toast';
import { addCompanyURL, confirmUserEmailURL } from '../../../utils/urls';
import { updateSpinner } from '../../../features/authentication/authenticationReducer';
import Layout from '../component/layout'
import { useNavigate, Link } from 'react-router-dom'



function Assigment() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [steps, setStep] = useState(['Facility', 'Concentrators', 'Meters', 'Delivery Note', 'Finish'])
	const [currentStep, setCurrentStep] = useState(steps[0])
	const [progressBar, setProgressBar] = useState('20%')


	const handleNextStep = async () => {
		if (currentStep === 'Facility') {
			setCurrentStep('Concentrators')
			setProgressBar('40%')
		}
    else if (currentStep === 'Concentrators') {
			setCurrentStep('Meters')
			setProgressBar('60%')
		}
    else if (currentStep === 'Meters') {
			setCurrentStep('Delivery Note')
			setProgressBar('80%')
		}
    else if (currentStep === 'Delivery Note') {
			setCurrentStep('Finish')
			setProgressBar('100%')
		}
	}
	const handleBackToPrevious = async () => {
    if (currentStep === 'Concentrators') {
			setCurrentStep('Facility')
			setProgressBar('20%')
		}
    else if (currentStep === 'Meters') {
			setCurrentStep('Concentrators')
			setProgressBar('40%')
		}
    else if (currentStep === 'Delivery Note') {
			setCurrentStep('Meters')
			setProgressBar('60%')
		}
    else if (currentStep === 'Finish') {
			setCurrentStep('Delivery Note')
			setProgressBar('80%')
		}

	}
	const handleFinish = async (e) => {
		try {
			dispatch(updateSpinner(true));
			e.preventDefault();
			

		}
		catch (err) {
			toastify(err.message, 'error')
			dispatch(updateSpinner(false));
		}
	}




	return (
		<Layout>


			<div className="page-header">
				<div className="page-block">
					<div className="row align-items-center">
						<div className="col-md-12">
							<ul className="breadcrumb">
								<li className="breadcrumb-item">
									<Link to={"/core/dashboard"}>Dashboard</Link>
								</li>
								<li className="breadcrumb-item">
									<Link to={"/core/water_management/assignment"}>Assignment</Link>
								</li>
								
							</ul>
						</div>

					</div>
				</div>
			</div>

			<div className="row">
				<div className="col-sm-12">
				
					<div id="basicwizard" className="form-wizard row justify-content-center">

						<div className="col-12">
							<div className="card">
								<div className="card-body p-3">
									<ul className="nav nav-pills nav-justified">
										<li className="nav-item" data-target-form="#contactDetailForm">
											<a href="#contactDetail" className={`nav-link ${currentStep === 'Facility' ? `active` : ``}`}>
												<i className="ph-duotone ph-buildings"></i>
												<span className="d-none d-sm-inline">Facility</span>
											</a>
										</li>

										<li className="nav-item" data-target-form="#contactDetailForm">
											<a href="#contactDetail" className={`nav-link ${currentStep === 'Concentrators' ? `active` : ``}`}>
												<i className="ph-duotone ph-copyright"></i>
												<span className="d-none d-sm-inline">Concentrators</span>
											</a>
										</li>
                    <li className="nav-item" data-target-form="#contactDetailForm">
											<a href="#contactDetail" className={`nav-link ${currentStep === 'Meters' ? `active` : ``}`}>
												<i className="ph-duotone ph-film-script"></i>
												<span className="d-none d-sm-inline">Meters</span>
											</a>
										</li>
                    <li className="nav-item" data-target-form="#contactDetailForm">
											<a href="#contactDetail" className={`nav-link ${currentStep === 'Delivery Note' ? `active` : ``}`}>
												<i className="ph-duotone ph-file-dashed"></i>
												<span className="d-none d-sm-inline">Delivery Note</span>
											</a>
										</li>
										



										<li className="nav-item">
											<a href="#finish" className={`nav-link icon-btn ${currentStep === 'Finish' ? `active` : ``}`}>
												<i className="ph-duotone ph-check-circle"></i>
												<span className="d-none d-sm-inline">Finish</span>
											</a>
										</li>

									</ul>
								</div>
							</div>
							<div className="card">
								<div className="card-body">
									<div className="tab-content">

										<div id="bar" className="progress mb-3" style={{ height: 7, width: progressBar }} >
											<div className="bar progress-bar progress-bar-striped progress-bar-animated bg-success"></div>
										</div>
										<div className={`tab-pane  ${currentStep === 'User Type' && `show active`}`} id="contactDetail">
											<form id="contactForm" method="get" action="#">

												<div className="row mt-4">

													<div className="col">
														<div className="row">
															<div className="col-sm-6">
																<div className="mb-3">
																	<label className="form-label">Select</label>
																	<select type="text" className="form-control" onChange={(e) => {

																	
																	}}>

																		<option value="Individual">Individual</option>
																		<option value="Company">Company</option>

																	</select>
																</div>
															</div>

														</div>
													</div>
												</div>
											</form>
										</div>

										<div className={`tab-pane  ${currentStep === 'User Information' && `show active`}`} id="contactDetail">
											<form id="contactForm" method="get" action="#">

											
											</form>
										</div>

										<div className={`tab-pane  ${currentStep === 'Company Information' && `show active`}`} id="jobDetail">
											<form id="jobForm" method="post" action="#">

												
											</form>
										</div>
										<div className={`tab-pane  ${currentStep === 'KYC' && `show active`}`} id="educationDetail">
											<form id="educationForm" method="post" action="#">

												
											</form>
										</div>
										<div className={`tab-pane  ${currentStep === 'Facility' && `show active`}`} id="finish">
										
										</div>

										<div className={`tab-pane  ${currentStep === 'Finish' && `show active`}`} id="finish">
											<div className="row d-flex justify-content-center">
												<div className="col-lg-6">
													<div className="text-center">
														<i className="ph-duotone ph-gift f-50 text-danger"></i>
														<h3 className="mt-4 mb-3">Congratulations</h3>
														<div className="mb-3">
															<div className="form-check d-inline-block">

																<label className="form-check-label" for="customCheck1">We have shared a welcome message and a login credentials on their email</label>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="d-flex wizard justify-content-between flex-wrap gap-2 mt-3">
											<div className="first">
												{/* <a href="javascript:void(0);" className="btn btn-secondary"> First </a> */}
											</div>
											<div className="d-flex">
                        							
												
                        
												{
													currentStep === 'Concentrators'
													&& <div className="previous me-2">
														<a href="javascript:void(0);" className="btn btn-secondary" onClick={handleBackToPrevious} > Back To Previous </a>
													</div>
												}
												{
													currentStep === 'Meters'
													&& <div className="previous me-2">
														<a href="javascript:void(0);" className="btn btn-secondary" onClick={handleBackToPrevious} > Back To Previous </a>
													</div>
												}
												{
													currentStep === 'Delivery Note'
													&& <div className="previous me-2">
														<a href="javascript:void(0);" className="btn btn-secondary" onClick={handleBackToPrevious} > Back To Previous </a>
													</div>
												}
												{
													currentStep === 'Finish'
													&& <div className="previous me-2">
														<a href="javascript:void(0);" className="btn btn-secondary" onClick={handleBackToPrevious} > Back To Previous </a>
													</div>
												}
												{
													currentStep !== 'Finish'
													&& <div className="next">
														<a href="javascript:void(0);" className="btn btn-secondary" onClick={handleNextStep}> Next Step </a>
													</div>
												}

												

												{
													currentStep === 'Finish'
													&& <div className="last">
														<a href="javascript:void(0);" className="btn btn-secondary" onClick={handleFinish}> Finish </a>
													</div>
												}

											</div>


										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		
		</Layout>
	)
}

export default Assigment