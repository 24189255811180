import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Link, useNavigate } from 'react-router-dom';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import { makeRequest2 } from '../../../utils/makeRequest';
import { toastify } from '../../../utils/toast';
import { getMessagesURL } from '../../../utils/urls'; // Importing addFacilityURL if needed
import Layout from '../component/layout';
import { filterObjectsByAllProperties } from '../../../utils/filterSearch';

function Messages() {
    const [messages, setMessages] = useState([]);
    const [messages2, setMessages2] = useState([]);
    const [recipient, setRecipient] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('')


    const getMessages = async () => {
        try {
            const response = await makeRequest2(getMessagesURL, 'GET', {});
            if (response.success) {
                setMessages(response.data);
                setMessages2(response.data);
            }
        } catch (error) {
            toastify(error.message, 'error');
        }
    };
    const handleInputSearch = async () => {
        try {
            

            let filter = [];

            if (recipient === '' && startDate === '' && endDate === '') {
                getMessages()
            }
            if (recipient && !startDate && !endDate) {
                // Only recipient provided
                filter = messages2.filter((x) => x.recipient === recipient);
            } else if (recipient && startDate && !endDate) {
                // Recipient and start date provided
                filter = messages2.filter((x) =>
                    x.recipient === recipient && new Date(x.sentOn) >= new Date(startDate)
                );
            } else if (recipient && startDate && endDate) {
                // Recipient, start date, and end date provided
                
                
                filter = messages2.filter((x) =>{
                    return x.recipient === recipient && new Date(x.sentOn) >= new Date(startDate) && new Date(x.sentOn) <= new Date(endDate)
                }
                    
                );
            }
            else if (!recipient && startDate && !endDate) {
                
                filter = messages2.filter((x) =>{
                    return new Date(x.sentOn) >= new Date(startDate)
                });
            }
            else if (!recipient && startDate && endDate) {
               
                filter = messages2.filter((x) =>{
                    return new Date(x.sentOn) >= new Date(startDate) && new Date(x.sentOn) <= new Date(endDate)
                });
            }
            else if (recipient && !startDate && endDate) {
                throw new Error('Start Date is required when End Date is provided');
            }

            setMessages(filter);
        } catch (err) {
            toastify(err.message, 'error');
        }
    };

    const messageStatusTemplate = (rowData) => {

        if (rowData.status === 'Not Sent') {
            return (
                <span className="badge rounded-pill text-bg-danger">Not Sent</span>
            )
        }
        else if (rowData.status === 'In Queue') {
            return (
                <span className="badge rounded-pill text-bg-warning">In Queue</span>
            )
        }
        else if (rowData.status === 'Sent') {
            return (
                <span className="badge rounded-pill text-bg-success">Sent</span>
            )
        }





    }

    useEffect(() => {
        getMessages();
    }, []);



    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/core/dashboard"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"/core/messages"}>Messages </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-3">
                                            <label>Enter Recipient:</label>
                                            <input type="number" className="form-control mt-2" placeholder="Enter Recipient" value={recipient} onChange={(e) => {
                                                const value = e.target.value;
                                                setRecipient(value)

                                            }} />

                                        </div>
                                        <div className="col-3">
                                            <label>Start Date:</label>
                                            <input type="date" className="form-control mt-2" placeholder="Enter Start Date" value={startDate} onChange={(e) => {
                                                const value = e.target.value;
                                                setStartDate(value)
                                            }} />

                                        </div>
                                        <div className="col-3">
                                            <label>End Date:</label>
                                            <input type="date" className="form-control mt-2" placeholder="Enter End Date" value={endDate} onChange={(e) => {
                                                const value = e.target.value;
                                                setEndDate(value)
                                            }} />

                                        </div>
                                        <div className="col-3">
                                            <button className="btn btn-primary mt-4" onClick={handleInputSearch}>Search</button>

                                        </div>

                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="dt-responsive table-responsive">
                                        <DataTable value={messages} emptyMessage="No messages found." sortMode="multiple" paginator rows={10} stripedRows tableStyle={{ minWidth: '50rem' }}>
                                            <Column field="recipient" sortable header="Recipient" ></Column>
                                            <Column field="type" header="Type"></Column>
                                            <Column field="subject" header="Subject"></Column>
                                            <Column field="body" header="Message"></Column>
                                            <Column field="Status" header="Status" body={messageStatusTemplate}></Column>
                                            <Column field="sentOn" header="Sent On"></Column>
                                        </DataTable>
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </Layout>
    );
}

export default Messages;
