
import React, { useState, useEffect } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {Link} from 'react-router-dom'
import 'primereact/resources/themes/lara-light-blue/theme.css';
//primereact/resources/themes/fluent-light/theme.css
//primereact/resources/themes/lara-light-blue/theme.css
//primereact/resources/themes/lara-light-indigo/theme.css
import { makeRequest2 } from '../../../utils/makeRequest'
import { toastify } from '../../../utils/toast'
import {getWaterMeterAccountURL} from '../../../utils/urls'
import Layout from '../component/layout'
import { filterObjectsByAllProperties } from '../../../utils/filterSearch';
function Accounts() {
  let testing_data = [
    {
      no:'1000',
      customer:'Jon Doe',
      project:"Test",
      meter:"12345",
      paymentType:"Prepaid",
      currentBalance:540,
      outStandingBalance:-300,

    }
  ]
  const [accounts, setAccounts] = useState([])
  const [filterAccounts, setFilteredAccounts] = useState([])
  const [searchTerm, setSearchTerm] = useState('')

  const getAccounts = async () => {
    try {
      const response = await makeRequest2(getWaterMeterAccountURL, 'GET', {})
      if (response.success) {
       setAccounts(testing_data)
       setFilteredAccounts(testing_data)

      }

    }
    catch (error) {
      toastify(error.message, 'error')
    }
  }
  const HandeInputSearch = async (value) => {
    try {
      setSearchTerm(value)
      if (value === '') {
        getAccounts()
      }
      else {
        const results = await filterObjectsByAllProperties(filterAccounts, value);
        setAccounts(results)
      }
    }
    catch (error) {
      toastify(error.message, 'error')
    }
  }

  const HandeFilter = async (value,filter) => {
    try {
      if(filter === 'All'){
        getAccounts()
      }
    
    }
    catch (error) {
      toastify(error.message, 'error')
    }
  }
 
  const outStandingBalanceBodyTemplate = (rowData) => {
      return (

        <span className="badge rounded-pill text-bg-danger">KES {rowData.outStandingBalance}</span>
      )
    

  };
  const currentBalanceBodyTemplate = (rowData) => {
    return (

      <span className="badge rounded-pill text-bg-info">KES {rowData.currentBalance}</span>
    )
  

};

  useEffect(() => {
    getAccounts()
  }, [])
  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/core/dashboard"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/core/water_management/water_accounts"}>Accounts </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">


          <div className="row">

            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-4">

                      <div className="row">
                        <div className="col-9">
                          <input className="form-control" placeholder="Search here" value={searchTerm} onChange={(e) => { HandeInputSearch(e.target.value) }} />

                        </div>
                        <div className="col-3" >
                          <div className="btn-group-dropdown" style={{ float: 'right' }}>
                            <button
                              className="btn btn-outline-default dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i className="ti ti-filter"></i>
                            </button>
                            <div className="dropdown-menu">
                            <Link className="dropdown-item" to="#!" onClick={()=>{HandeFilter('','All')}}>
                                All
                              </Link>
                              
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  
                  </div>

                </div>
                <div className="card-body">
                  <div className="dt-responsive table-responsive">
                    <DataTable value={accounts} emptyMessage="No water accounts found." sortMode="multiple" paginator rows={5} stripedRows tableStyle={{ minWidth: '50rem' }}>
                    <Column field="no" sortable header="No"></Column>
                      <Column field="customer" sortable header="Customer"></Column>
                      <Column field="project" header="Project"></Column>
                      <Column field="meter" header="Meter"></Column>
                      <Column field="paymentType" header="Payment Type"></Column>
                      <Column field="currentBalance" header="Current Balance" body={currentBalanceBodyTemplate}></Column>
                      <Column field="outStandingBalance" sortable header="Outstanding Balance"  body={outStandingBalanceBodyTemplate}></Column>
                    </DataTable>


                  </div>
                </div>
              </div>
            </div>


          </div>

        </div>
      </div>
    </Layout>
  )
}


export default Accounts