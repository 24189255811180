import React, {  useState } from 'react'
import { useDispatch } from 'react-redux';
import Papa from 'papaparse';
import {Link} from 'react-router-dom'
import Layout from '../component/layout'
import { updateSpinner } from '../../../features/authentication/authenticationReducer';
import { toastify } from '../../../utils/toast';
import { makeRequest2 } from '../../../utils/makeRequest';
import { importWaterMetersURL } from '../../../utils/urls';


function ImportWaterMeters() {
  const [csvData, setCsvData] = useState([]);
  const [fileName, setFileName] = useState('');
  const [proceed, setProceed] = useState('Download Template')
  const dispatch = useDispatch();
  const HandleFileChange = async (event) => {
    try {
      const file = event.target.files[0];
      dispatch(updateSpinner(true))
      if (file && file.type === 'text/csv') {
        setFileName(file.name);
        Papa.parse(file, {
          complete: (results) => {
            setCsvData(results.data);
            ImportMeters(results.data)
          },
          header: true
        });
      } else {
        throw new Error('Please upload a valid CSV file.')
      }
    }
    catch (err) {
      toastify(err.message, 'error')
      dispatch(updateSpinner(false))
    }

  };
  const ImportMeters = async (data) => {
    try {
      const body = {
        concentrators: data
      }

      const response = await makeRequest2(importWaterMetersURL, 'POST', body)
      if(response.success){
        setTimeout(()=>{
          toastify(response.data, 'success')
          dispatch(updateSpinner(false))
          setProceed('Download Template')
          setFileName('')
          setCsvData([])
        },2000)
      }
      else
      {
        throw new Error(response.error)
      }
    }
    catch (err) {
      toastify(err.message, 'error')
      dispatch(updateSpinner(false))
    }
  };

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/core/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/core/water_management/water_meters"}>Meters</Link>
                </li>
                <li className="breadcrumb-item" aria-current="page">
                  Import Meters
                </li>
              </ul>
            </div>

          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body">
              <div className="mb-4">
                <Link to={"/core/water_management/water_meters"}><span><i className="ti ti-arrow-narrow-left"></i> Back</span></Link>

              </div>
              <div className="container">
                <div className="text-center mt-5">
                  {
                    proceed === 'Download Template' &&
                    <>
                      <Link to="/assets/templates/ImportWaterMetersTemplateFile.csv" download>
                        <i className="fas fa-file-csv" style={{ fontSize: 100, color: '#6610f2' }} />
                      </Link>
                      <Link to="/assets/templates/ImportWaterMetersTemplateFile.csv" download style={{ color: 'black' }}>
                        <p className="mt-4"><i className="fas fa-cloud-download-alt"></i> Download CSV template file</p>
                      </Link>

                      <p className="mt-5"><Link to="#" onClick={() => {
                        setProceed("Import")
                      }}><strong><i className="fas fa-long-arrow-alt-right"></i> Click Here to Import a File</strong></Link></p>
                    </>

                  }


                </div>
                {
                  proceed !== 'Download Template' &&
                  <form
                    action="../assets/json/file-upload.php"
                    className="dropzone"
                  >
                    <div className="fallback">
                      {
                        fileName !== "" &&
                        <>
                          <span><strong>File Name:</strong> <i>{fileName}</i> </span>
                          <br />
                          <br />
                          <span><strong>Data Entries: </strong><span className="badge bg-light-dark">{csvData.length}</span> </span>
                          <br />
                          <br />
                        </>

                      }
                      

                      <input name="file" type="file" onChange={HandleFileChange} accept=".csv" multiple="" />
                    </div>
                   
                  </form>

                }


              </div>
            </div>
          </div>
        </div>
      </div>

    </Layout>
  )
}
export default ImportWaterMeters