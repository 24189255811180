export function transformEmail(input) {
    if (input.includes('@')) {
        // Split email into username and domain
        const [username, domain] = input.split('@');
        
        // Abbreviate username and mask the rest
        const maskedUsername = username.slice(0, 4) + '.'.repeat(username.length - 4);
        
        // Concatenate transformed email
        return `${maskedUsername}@${domain}`;
    } else {
        // If it's a phone number, mask all but the last four digits
        return input.slice(0, 4) + '.'.repeat(input.length - 4);
    }
}
