import {
    createBrowserRouter
} from "react-router-dom";
// // import { BrowserRouter, Routes, Route } from "react-router-dom";

// PAGES    

import Login from '../components/authentication/login'
import ForgotPassword from '../components/authentication/forgotPassword'
import CheckEmail from '../components/authentication/checkEmail';
import CodeVerification from '../components/authentication/codeVerification';
import ResetPassword from '../components/authentication/resetPassword';
import Error404Page from '../components/error/Error404Page'


// CORES
import Dashboard from '../components/core/dashboard/dashboard';
import WaterConcentrators from '../components/core/water_management/concentrators'
import WaterMeters from '../components/core/water_management/meters'
import WaterAccounts from '../components/core/water_management/accounts'
import AddWaterMeter from '../components/core/water_management/add_meter'
import Assignment from '../components/core/water_management/assignment'
import AddWaterConcentrator from "../components/core/water_management/add_concentrator";
import ViewWaterConcentrator from "../components/core/water_management/view_concentrator"
import ImportWaterConcentrators from '../components/core/water_management/import_concentrators'

import Companies from '../components/core/company_management/companies'
import AddCompany from '../components/core/company_management/add_company'
import ViewCompany from '../components/core/company_management/view_company'

import Facilities from '../components/core/facility_management/facilities'
import AddFacility from "../components/core/facility_management/add_facility";
import ViewFacility from "../components/core/facility_management/view_facility";

import Tickets from '../components/core/support/tickets'
import ImportWaterMeters from "../components/core/water_management/import_meters";
import ViewTickets from "../components/core/support/view_tickets";
import Settings from '../components/core/settings/settings'
import ViewUnit from "../components/core/unit_management/view_unit";
import ImportUnit from '../components/core/unit_management/import_unit'

import Message from '../components/core/messages/messages'


export const router = createBrowserRouter([
    {
        path: "/",
        element: <Login />,
    },
    {
        path: "/forgot_password",
        element: <ForgotPassword />,
    },
    {
        path: "/check_email",
        element: <CheckEmail />,
    },
    {
        path: "/code_verification",
        element: <CodeVerification />,
    },
    {
        path: "/reset_password/:userId",
        element: <ResetPassword />,
    },
    {
        path: "/core/dashboard",
        element: <Dashboard />,
    },
    {
        path: "/core/water_management/water_concentrators",
        element: <WaterConcentrators />,
    },
    {
        path: "/core/water_management/add_water_concentrator",
        element: <AddWaterConcentrator />,
    },
    {
        path: "/core/water_management/view_concentrator/:concentratorId",
        element: <ViewWaterConcentrator />,
    },
    {
        path: "/core/water_management/import_water_concentrators",
        element: <ImportWaterConcentrators />,
    },
    {
        path: "/core/water_management/water_meters",
        element: <WaterMeters />,
    },
    {
        path: "/core/water_management/add_water_meter",
        element: <AddWaterMeter />,
    },
    {
        path: "/core/water_management/assignment",
        element: <Assignment />,
    },
    {
        path: "/core/water_management/import_water_meters",
        element: <ImportWaterMeters />,
    },
    {
        path: "/core/water_management/water_accounts",
        element: <WaterAccounts />,
    },
    {
        path: "/core/companies",
        element: <Companies />,
    },
    {
        path: "/core/companies/add_company",
        element: <AddCompany />,
    },
    {
        path: "/core/companies/view_company/:companyId",
        element: <ViewCompany />,
    },
    {
        path: "/core/facilities",
        element: <Facilities />,
    },
    {
        path: "/core/facilities/add_facility/:companyId",
        element: <AddFacility />

    },
    {
        path: "/core/facilities/view_facility/:facilityId",
        element: <ViewFacility />

    },
    {
        path: '/core/units/import_units/:facilityId',
        element: <ImportUnit />
    },
    {
        path: "/core/units/view_unit/:unitId",
        element: <ViewUnit />

    },


    {
        path: "/core/tickets",
        element: <Tickets />,
    },
    {
        path: "/core/view_tickets",
        element: <ViewTickets />,
    },
    {
        path: '/core/messages',
        element: <Message />
    },

    {
        path: "/core/settings",
        element: <Settings />,
    },
    {
        path: "*",
        element: <Error404Page />,
    },
]);