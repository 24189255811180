import React, { useEffect } from 'react';
import Nav from './nav'
import Header from './header'
import Footer from './footer'
import { getItem, clearStorage } from '../../../utils/localStorage';
import { toastify } from '../../../utils/toast';
import { Link, useNavigate } from 'react-router-dom';
import { makeRequest } from '../../../utils/makeRequest';
import { checkTokenExpirationURL } from '../../../utils/urls'

function Layout({ children }) {
  const navigate = useNavigate()
  const checkUserExistInLocalStorage = async () => {
    const userExist = await getItem('COREUSER')

    if (userExist === null || userExist === undefined) {
      toastify('You have been logged out, Kindly login', 'error')
      navigate('/')
    }
  }
  const checkJwtExpiration = async () => {
    try {
      const userDetailsValue = await getItem('COREUSER')
      const userDetails = JSON.parse(userDetailsValue)

      const token = userDetails.authToken

      const response = await makeRequest(checkTokenExpirationURL, 'POST', {
        token: token
      })

      if (response.success) {
        if (response.success.data) {
          await clearStorage()
          navigate('/')
        }
      }

    }
    catch (err) {
      console.log(err.message)
    }
  }
  useEffect(() => {
    checkUserExistInLocalStorage()
    checkJwtExpiration()
  }, [])
  return (
    <>
      <Nav />
      <Header />
      <div className="pc-container">
        <div className="pc-content">
          {children}
        </div>
      </div>

      <Footer />
    </>
  );
}
export default Layout;