import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeRequest2 } from '../../../utils/makeRequest';
import { toastify } from '../../../utils/toast';
import { addWaterMeterURL, getWaterMeterSettings } from '../../../utils/urls';
import { updateSpinner } from '../../../features/authentication/authenticationReducer';
import Layout from '../component/layout';

function AddMeter() {
  const dispatch = useDispatch();
  const [manufacturer, setManufacturer] = useState('');
  const [serialNumber, setSerialNumber] = useState('');
  const [size, setSize] = useState('');
  const [initialValue, setInitialValue] = useState(0);
  const [currentValue, setCurrentValue] = useState(0);
  const [meterLocation, setMeterLocation] = useState(''); // New state for Meter Location

  const [manufacturers, setManufacturers] = useState([]);
  const [meterSizes, setMeterSizes] = useState([]);


  const fetchManufacturers = async () => {
    try {
      const response = await makeRequest2(getWaterMeterSettings, 'GET', {});
  
      if (response.success) {
        // Make sure to access the correct key in the response
        setManufacturers(response.data.manufacturers); 
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      toastify(err.message, 'error');
    }
  };


  const fetchMeterSizes = async () => {
    try {
      const response = await makeRequest2(getWaterMeterSettings, 'GET', {});
  
      if (response.success) {
        // Make sure to access the correct key in the response
        setMeterSizes(response.data.meterSizes); 
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      toastify(err.message, 'error');
    }
  };
  


  const HandleClearButton = () => {
    setManufacturer('');
    setSerialNumber('');
    setSize('');
    setInitialValue(0);
    setCurrentValue(0);
    setMeterLocation(''); // Clear Meter Location
  };

  const HandleSubmitButton = async () => {
    dispatch(updateSpinner(true));
    try {
      if (manufacturer === '') {
        throw new Error('Manufacturer is required.');
      } else if (serialNumber === '') {
        throw new Error('Serial Number is required.');
      } else if (initialValue < 0) {
        throw new Error('Initial Value should be greater than 0.');
      }
      //  else if (currentValue < 0) {
      //   throw new Error('Current Value should be greater than 0.');
      // } 
      else if (currentValue < initialValue) {
        throw new Error('Current Value should be greater than the Initial Value.');
      } else {
        const body = {
          serialNumber,
          manufacturer,
          initialValue,
          // currentValue,
          size,
          // meterLocation, // Include Meter Location in the request body
          // protocol: 'PayServe',
        };

        const response = await makeRequest2(addWaterMeterURL, 'POST', body);
        if (response.success) {
          setTimeout(() => {
            dispatch(updateSpinner(false));
            toastify(response.data, 'success');
          }, 1000);
        } else {
          throw new Error(response.error);
        }
      }
    } catch (err) {
      dispatch(updateSpinner(false));
      toastify(err.message, 'error');
    }
  };


  useEffect(() => {
    fetchManufacturers();
    fetchMeterSizes();
  }, []);

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/core/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/core/water_management/water_meters"}>Meters</Link>
                </li>
                <li className="breadcrumb-item" aria-current="page">
                  Add Meter
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body">
              <div className="mb-4">
                <Link to={"/core/water_management/water_meters"}><span><i className="ti ti-arrow-narrow-left"></i> Back</span></Link>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <label className="form-label">Manufacturer<span style={{ color: 'red' }}>*</span></label>
                      <select className="mb-3 form-select" value={manufacturer} onChange={(e) => setManufacturer(e.target.value)}>
                      <option value="">-- Select --</option>
                      {manufacturers.map((name, index) => (
                        <option key={index} value={name}>
                          {name}
                        </option>
                      ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <label className="form-label">Serial Number<span style={{ color: 'red' }}>*</span></label>
                      <input type="text" className="mb-3 form-control" value={serialNumber} onChange={(e) => setSerialNumber(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <label className="form-label">Size</label>
                      <select className="mb-3 form-select" value={size} onChange={(e) => setSize(e.target.value)}>
                      <option value="">-- Select --</option>
                      {meterSizes.map((metersize, index) => (
                        <option key={index} value={metersize}>
                          {metersize}
                        </option>
                      ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <label className="form-label">Initial Value</label>
                      <input type="number" value={initialValue} className="mb-3 form-control" onChange={(e) => setInitialValue(e.target.value)} />
                    </div>
                  </div>
                </div>

                <div className="text-end mt-4">
                  <button type="submit" className="btn btn-outline-secondary" onClick={HandleClearButton}>
                    Clear
                  </button>
                  &nbsp;
                  <button type="submit" className="btn btn-primary" onClick={HandleSubmitButton}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default AddMeter;
