
import React, { useState, useEffect } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {Link} from 'react-router-dom'
import 'primereact/resources/themes/lara-light-blue/theme.css';
//primereact/resources/themes/fluent-light/theme.css
//primereact/resources/themes/lara-light-blue/theme.css
//primereact/resources/themes/lara-light-indigo/theme.css
import { makeRequest2 } from '../../../utils/makeRequest'
import { toastify } from '../../../utils/toast'
import { getWaterConcentratorsURL } from '../../../utils/urls'
import Layout from '../component/layout'
import { filterObjectsByAllProperties } from '../../../utils/filterSearch';
function Concentrators() {
  const [concentrators, setConcentrators] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
 
  const getConcentrators = async () => {
    try {
      const response = await makeRequest2(getWaterConcentratorsURL, 'GET', {})
      if (response.success) {
        setConcentrators(response.data)

      }
    }
    catch (error) {
      toastify(error.message, 'error')
    }
  }
  const HandeInputSearch = async(value)=>{
    try {
      setSearchTerm(value)
         if(value === ''){
          getConcentrators()
         }
         else{
          const results = await filterObjectsByAllProperties(concentrators,value);
          setConcentrators(results)
         }
    }
    catch(error){
      toastify(error.message, 'error')
    }
  }
  const inStockBodyTemplate = (rowData) => {
    if (rowData.inStock) {
      return (

        <i className="ti ti-square-check" style={{ fontSize: 25, color: 'green' }}></i>
      )
    }
    else {
      return (

        <i className="ti ti-square-x" style={{ fontSize: 25, color: 'red' }}></i>
      )
    }

  };
  const isFaultyBodyTemplate = (rowData) => {
    if (rowData.inFaulty) {
      return (

        <i className="ti ti-square-check" style={{ fontSize: 25, color: 'green' }}></i>
      )
    }
    else {
      return (

        <i className="ti ti-square-x" style={{ fontSize: 25, color: 'red' }}></i>
      )
    }
  };
  const isInstalledBodyTemplate = (rowData) => {
    if (rowData.isInstalled) {
      return (

        <i className="ti ti-square-check" style={{ fontSize: 25, color: 'green' }}></i>
      )
    }
    else {
      return (

        <i className="ti ti-square-x" style={{ fontSize: 25, color: 'red' }}></i>
      )
    }
  };
  const statusBodyTemplate = (rowData) => {
   
    if (rowData.status === 'Online') {
      return (

        <span className="badge rounded-pill text-bg-success">Online</span>
      )
    }
    else {
      return (

        <span className="badge rounded-pill text-bg-danger">Offline</span>
      )
    }
    
  };

  const serialNumberTemplate = (rowData) => {
    return (<Link to={'/core/water_management/view_concentrator/' + rowData._id}>
        <strong>{rowData.serialNumber}</strong>
    </Link>)
}
  useEffect(() => {
    getConcentrators()
  }, [])
  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/core/dashboard"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/core/water_management/water_concentrators"}>Concentrators </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">


          <div className="row">

            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-3">
                      <input className="form-control" placeholder="Search here" value={searchTerm} onChange={(e) => { HandeInputSearch(e.target.value)}} />
                    </div>
                    <div className="col-9">
                      <Link to={"/core/water_management/add_water_concentrator"} type="button" className="btn btn-primary float-end" style={{ color: 'white' }}>
                        <i className="ti ti-plus" />&nbsp;Add</Link>

                      <Link to={"/core/water_management/import_water_concentrators"} type="button" className="btn btn-primary float-end" style={{ marginRight: 4 }}>
                        <i className="ti ti-file-import" />
                        &nbsp;
                        Import
                      </Link>
                    </div>
                  </div>

                </div>
                <div className="card-body">
                  <div className="dt-responsive table-responsive">
                    <DataTable value={concentrators} emptyMessage="No concentrators found." sortMode="multiple" paginator rows={5} stripedRows tableStyle={{ minWidth: '50rem' }}>

                      <Column field="serialNumber" sortable header="Serial Number" body={serialNumberTemplate}></Column>
                      <Column field="manufacturer" header="Manufacturer"></Column>
                      <Column field="inStock" header="In Stock" body={inStockBodyTemplate}></Column>
                      <Column field="isFaulty" header="Is Faulty" body={isFaultyBodyTemplate}></Column>
                      <Column field="isInstalled" header="Is Installed" body={isInstalledBodyTemplate} ></Column>
                      <Column field="status" sortable header="Status" body={statusBodyTemplate}></Column>
                    </DataTable>
                    <br/>
                  
                  </div>
                </div>
              </div>
            </div>


          </div>

        </div>
      </div>
    </Layout>
  )
}


export default Concentrators